/* index.css */
body {
  margin: 0;
  background: #000000;
  /* Black background for better contrast */
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  /* Default text color */
}

/* Neon Glow Effect */
.neon-button {
  background: linear-gradient(135deg, #8e2de2, #4a00e0);
  /* Violet to purple gradient */
  border: none;
  border-radius: 50%;
  box-shadow:
    0 0 5px #8e2de2,
    0 0 10px #8e2de2,
    0 0 20px #8e2de2,
    0 0 40px #4a00e0,
    0 0 80px #4a00e0,
    inset 0 0 10px #8e2de2;
  color: #fff;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}

/* Hover Effect */
.neon-button:hover {
  box-shadow:
    0 0 10px #8e2de2,
    0 0 20px #8e2de2,
    0 0 40px #4a00e0,
    0 0 80px #4a00e0,
    inset 0 0 20px #8e2de2;
}

/* Active State */
.neon-button:active {
  box-shadow:
    0 0 5px #8e2de2,
    0 0 10px #8e2de2,
    0 0 20px #4a00e0,
    inset 0 0 5px #4a00e0;
}

/* Loading/Connecting Effect */
.connecting-effect {
  animation: pulseNeon 1.5s infinite;
}

@keyframes pulseNeon {
  0% {
    box-shadow:
      0 0 5px #8e2de2,
      0 0 10px #8e2de2,
      0 0 20px #8e2de2,
      0 0 40px #4a00e0,
      0 0 80px #4a00e0,
      inset 0 0 10px #8e2de2;
  }

  50% {
    box-shadow:
      0 0 10px #8e2de2,
      0 0 20px #8e2de2,
      0 0 40px #4a00e0,
      0 0 80px #4a00e0,
      inset 0 0 20px #8e2de2;
  }

  100% {
    box-shadow:
      0 0 5px #8e2de2,
      0 0 10px #8e2de2,
      0 0 20px #8e2de2,
      0 0 40px #4a00e0,
      0 0 80px #4a00e0,
      inset 0 0 10px #8e2de2;
  }
}

/* In-Progress Call Effect */
.in-progress-effect {
  animation: electricGlow 1s infinite;
}

@keyframes electricGlow {
  0% {
    box-shadow:
      0 0 10px #ff00ff,
      0 0 20px #ff00ff,
      0 0 30px #ff00ff,
      0 0 40px #ff00ff,
      inset 0 0 10px #ff00ff;
    transform: rotate(0deg);
  }

  50% {
    box-shadow:
      0 0 30px #ff00ff,
      0 0 60px #ff00ff,
      0 0 90px #ff00ff,
      0 0 120px #ff00ff,
      inset 0 0 30px #ff00ff;
    transform: rotate(5deg) scale(1.05);
  }

  100% {
    box-shadow:
      0 0 10px #ff00ff,
      0 0 20px #ff00ff,
      0 0 30px #ff00ff,
      0 0 40px #ff00ff,
      inset 0 0 10px #ff00ff;
    transform: rotate(0deg);
  }
}

.in-progress-effect {
  animation: electricGlow 0.8s infinite;
}

/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles (if any) */

/* index.css */